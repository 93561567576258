<template>
  <pz-form-flujo
    :forward="handleSend"
  >
    <span slot="form_title">
      {{ $t('commons.docuSing.title') }}
    </span>
    <span slot="form_subtitle">
      {{ $t('commons.docuSing.sub_title', {reenviar: $t('forms_flujo.validacion_sms_default.resend_btn')}) }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      :model="formulario"
      status-icon
      :rules="rules_formulario_validarTelefono"
      @validate="handleValidate"
    >
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
      >
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="identity"
            :error="errors.get('numero_documento')"
          >
            <pz-identity-document
              v-model="formulario.identity"
              prop="identity"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
      >
        <el-col
          :xl="{span: 12}"
          identity
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="codigo_verificacion"
            :rules="formulario.codigo_verificacion===''?[]:[required('')]"
            :label="$t('forms_flujo.validacion_sms_default.verification_code')"
            :error="errors.get('codigo_verificacion')"
          >
            <el-input
              v-model="formulario.codigo_verificacion"
              :type="'text'"
              :placeholder="$t('forms_flujo.validacion_sms_default.verification_code')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-col
      slot="buttons"
      :span="24"
      class="center-block text-center"
    >
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <pz-button
          v-if="formulario.codigo_verificacion===''"
          key="resend"
          :key-press="13"
          icon="el-icon-refresh"
          :button-name="$t('forms_flujo.validacion_sms_default.resend_btn')"
          @click="handleResend"
        />
        <pz-button
          v-else
          key="send"
          type="primary"
          :button-name="$t('continue')"
          @click="handleSend"
        />
        <!--<pz-captcha-button-->
        <!--v-else-->
        <!--key="send"-->
        <!--v-model="formulario['captcha']"-->
        <!--:forward="handleSend"-->
        <!--/>-->
      </transition>
    </el-col>
  </pz-form-flujo>
</template>
<script>
import {required} from '@/commons/utils/Rules';
import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";

export default {
    name: "PzFadForm",
    components: {PzIdentityDocument, PzFormFlujo},
    mixins: [PzBaseForm],
    props: {
        loan: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            reference: 'formularioNaat',
            formulario: {
                codigo_verificacion: '',
                numero_documento: {},
                identity: {},
                loan: this.loan
            },
            rules_formulario_validarTelefono: {
                'identity.document': [required('')],
                'identity.number': [required('')],
            }
        }
    },
    methods: {
        required,
        setNumeroDoc() {
            this.formulario.numero_documento = {[this.formulario.identity.document]: this.formulario.identity.number};
        },
        handleResend() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.setNumeroDoc();
            let isvalid = this.handleSave();
            if (isvalid) {
                this.$emit('input', this.formulario);
                this.$emit('resend');
            }
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        },
        handleSend() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.setNumeroDoc();
            let isvalid = this.handleSave();
            if (isvalid) {
                this.$emit('input', this.formulario);
                this.$emit('send');
            }
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        }
    }
}
</script>

